import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Title from '../SectionTitle';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image";

export default function PhotographyGrid() {
  const data = useStaticQuery(graphql`{
  allFile(
    filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, sourceInstanceName: {eq: "photography"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(height: 1080, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`)
  return (
    <div>
      <Title/>
      <section id="more-photos" >
        <Container className="photography-wrapper">
          <Fade bottom duration={1000} delay={600} distance="30px">
            <Row >
              {
                data.allFile.edges.map(image => (
                  <Col md={4} sm={12}>
                    <GatsbyImage
                      image={image.node.childImageSharp.gatsbyImageData}
                      alt={image.node.base.split(".")[0]} />
                  </Col>
                )
                )
              }
            </Row>
          </Fade>
        </Container>
      </section>
    </div>
  );

};