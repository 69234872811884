import React, {useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Title from '../SectionTitle';
import { Container, Carousel } from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image";

export default function Photography() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

const data = useStaticQuery(graphql`{
  allFile(
    filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, sourceInstanceName: {eq: "showcase"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`)

  return (
    <div>
      <Title/>
      <section id="photography-showcase">
        <Container className="photography-wrapper">
          <Fade bottom duration={1000} delay={600} distance="30px">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {
                data.allFile.edges.map(
                  image => (
                    <Carousel.Item>
                      <GatsbyImage
                        image={image.node.childImageSharp.gatsbyImageData}
                        // fixed={image.node.childImageSharp.fixed}
                        alt={image.node.base.split(".")[0]} />
                    </Carousel.Item>
                  )
                )
              }
            </Carousel>
          </Fade>
        </Container>
      </section>
    </div>
  );

};