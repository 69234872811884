import React from 'react';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import PhotographyGrid from '../components/photography/PhotographyGrid';
import PhotographyCarousel from '../components/photography/PhotographyCarousel';

export default function PhotosPage() {
  return (
    <Layout>
      <PageTitle title="Photography" head="photohead" />
      <section className="photography-section" >
        <PhotographyCarousel />
        <PhotographyGrid />
      </section>
    </Layout>
  )
}
